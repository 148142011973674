import React from 'react';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class Spiritualism extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Spiritualism - Graham Ravey</h1>
                <div className="libraryImages">
                    <StaticImage src="../../../images/spiritualism.jpg" alt="Sanchin"/>
                </div>

                <p>Martial Arts is for individuals to discover themselves through rigorous physical training of the
                    body, eventually freeing the spirit of it's physical boundaries to pursue the moral truth through
                    realisation.</p>

                <p>The spiritual side of Martial Arts is the mental training of the mind and soul. The body enables us
                    to have hands on experience to understand and realise the importance of the unseen mental processes
                    in the higher order of things, these are all one and the same, inexplicably inter-twined into the
                    matrix that is the essence of what we really stand for.</p>

                <p>If we neglect our spirit or cease to reflect, tradition and philosophy can diminish in importance to
                    the point where balancing the ego becomes impossible. A student who never finds the spiritual side
                    of the art will only concentrate on the physical and therefore become unbalanced.</p>

                <p>I have been studying Martial Arts for a over 40 years and have read in many text that date back
                    thousands of years that a basic desire for money, position and power can be a catalyst for a wasted
                    life. To desire theses things by means of abuse to the community, for self gratification of ego can
                    be intrinsically evil, though from a business point of view can be seen as progress. Spiritually
                    this may stagnate an individuals growth. </p>

                <p>Martial teachers alongside the rest of the population have their needs, some get their needs and
                    wants mixed up, we all need transport, but some want a Porsche! We all need a roof over our heads,
                    but some want a mansion! Money is needed to run Dojo's, for equipment, rent, salaries, but there are
                    those who want to become rich of it. This leads to watering down and denial of the one true path to
                    self knowing. Respect for the Masters and the true growth of the way of the Art.</p>

                <p>To be humble and live humble comes under the spirit of Martial Arts. Master Gichin Funakoshi
                    said <strong>"I have no money problems because I have no money"</strong>. Once spiritualism is
                    neglected the flavour of the teachers lesson changes. <strong>Instead of the student having to
                        strive to match the standard of the masters, the standard is lowered to match that of the
                        student.</strong> These symptoms are prevalent in many schools causing erosion of the way.
                    Milder discipline and training is given so as not to scare the student away, frequent gradings are
                    given to keep their egos fed. Patience is a great lesson to learn and the only way to learn is by
                    waiting, etiquette is another area that is selectively edited or being left altogether.</p>

                <p>Once the spirit is neglected one or all of these cancers will grow until there is little of the
                    original strong and successful form of teaching left.</p>
                <p>Living off Martial Arts requires sound judgment on the part of the teacher, taking care not to
                    sacrifice the morality of the art for personal gain or to the detriment of the generations past,
                    presence and future. The responsibility of education fall on seniors in any place of learning, the
                    young need constant tutoring and guidance to keep them strong and focused on the path within
                    themselves.</p>

                <p>I once attended a summer camp abroad at which time I was a fourth Dan. I was approached a green belt
                    who asked me to look at his Kata, I expected him to do a drill of the level of green belt and was
                    shocked when he proceeded to do Kata of a third Dan level and not very well at that. He had
                    developed many mistakes and made them his own, by which I mean he had trained them into himself. I
                    found out that he had taught himself by way of video.</p>

                <p>Such a seeker of the way will inadvertently take many steps backwards as a video lacks all the
                    importance of hands on tuition from a teacher. His lessons in patience and ego refining were put to
                    one side as soon as he turned on the video and before this man could be taught properly, he would
                    have to be retrained from all his mistakes. Don't get me wrong, I think videos can be a great asset
                    to martial arts as a reference library for senior students of all styles, to appreciate the masters
                    as they really are. But for novices to try and copy advanced teaching, is a recipe for disaster.</p>

                <p>This could be solved by having all parties decide that there is indeed a problem. Perhaps a licence
                    could be shown before purchasing advanced teaching videos? Or perhaps such videos are only for
                    accredited Dojo's. I can not see to many problems evolving as long as they have ample explanations
                    of the importance of warming up and down. Also a guide as to how to recognise the difference between
                    a fine overall teacher to a fly-by-nighter, anyone can go out and buy a black belt (which is another
                    huge problem the credibility of martial arts faces). Such pseudo teachers set themselves up as the
                    be all and end all of Martial artists, how "they" do it, is the way everybody should do it. An
                    illustration of how wrong this view comes from personal experiences.</p>

                <p>I am a tall man and have changed my basic application instruction over the years through
                    understanding that how my body type applies a technique is different to how a short fellow would
                    apply the same technique. If I taught my flavour of the gospel, I would be neglecting all students
                    of different statures in my Dojo. So what I teach must stay general in form, acceptable to all
                    shapes and sizes of people. The basic way is flexible and adjustable to suit all, a gift from our
                    masters making it possible for every person to reach their best. There is a fine line between right
                    and wrong in life and the way of Martial Arts is no exception.</p>

                <p>Some people have pride in what they do, but don't go over to vanity. To need things is normal, but
                    when does need become greed? To admire someone for accomplishment is healthy, but over stepping the
                    line becomes hero worship. Take care, enjoy your Martial Arts both physically and mentally to the
                    fullest.
                    Read philosophy and most of all, contemplate it. Otherwise it will be like eating without digesting,
                    no nourishment will take place. There are victories of the soul and spirit. Sometimes even when you
                    lose, you win. Remember, nourishment for the soul starts with philosophy.</p>

                <p><strong>Sensei Graham Ravey</strong></p>
            </Layout>);
    }
}

export default Spiritualism;
